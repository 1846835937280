import React from 'react';
import 'styles/error.css';

export function Error() {
	return (
		<div>
			<div className="errorPage">
				<h1>404</h1>
				<h5>Something went wrong...</h5>
			</div>
		</div>
	);
}